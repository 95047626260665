import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { Footer, FooterTemplate } from 'page-components/shared/footer';
import { SignupContainer } from 'page-components/welcome/SignUpContainer';
import { WelcomeWrapper } from 'page-components/welcome/welcomeUtils';
import React, { FC } from 'react';

interface SignupProps {}

const Signup: FC<SignupProps> = () => (
  <Layout>
    <Navigation simpleNav />
    <PageWrapper background={colors.greenLight}>
      <WelcomeWrapper maxWidth={900}>
        <SignupContainer />
      </WelcomeWrapper>
      <Footer template={FooterTemplate.LegalDisclaimerOnly} />
    </PageWrapper>
  </Layout>
);

export default Signup;

export const Head = () => (
  <SEO
    title="Look Forward To More In Retirement"
    description="Retirable® gives adults 50+ retirement income management and guidance to help them confidently maintain a comfortable lifestyle."
  />
);
