import { useLocation } from '@reach/router';
import axios from 'axios';
import {
  AdvisorCallTimeStep,
  AgeStep,
  BadFitStep,
  ConcernsStep,
  ConfirmedStep,
  CraftingRetirementPlanLoadingStep,
  EmailAndFirstNameStep,
  MaritalStep,
  NoCallStep,
  PhoneStep,
  RetirementConcernsOptions,
  SavingsStep,
  WhenRetiringOptions,
  WhenRetiringStep,
} from 'component-library/components/form/fields';
import { AdvisorCallTimeOptions } from 'component-library/components/form/fields/AdvisorCallTimeStep';
import {
  FormButton,
  FormikWizard,
  handleTrack,
} from 'component-library/components/form/FormikWizard';
import { WizardStep } from 'component-library/components/form/WizardStep';
import { Flex } from 'component-library/components/layout/Flex';
import { getUrlTrackingDetails } from 'helpers/getUtmDataFromUrl';
import React, { FC, useEffect, useState } from 'react';
import { adConversionEvents, identify, track } from 'utils/analytics';

import {
  SignUpContainerSteps,
  SignUpFormSchema,
  validateFields,
} from './signUpUtils';

const FORM_NAME = 'Signup';

export interface SignUpFormData {
  concerns: RetirementConcernsOptions[];
  currentAge: number;
  whenRetiring: WhenRetiringOptions;
  maritalStatus: string;
  savings: number;
  firstName: string;
  email: string;
  advisorCallTime: AdvisorCallTimeOptions;
  phone: number;
}

interface SignupContainerProps {}

export const SignupContainer: FC<SignupContainerProps> = () => {
  const [userId, setUserId] = useState();
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const location = useLocation();
  const utmTrackingDetails = getUrlTrackingDetails(location.search);

  const createMarketingLead = async (data: SignUpFormData) => {
    setFormIsSubmitting(true);
    const resp = await axios.request({
      method: 'post',
      url: '/api/createMarketingLead',
      data: { ...data, ...utmTrackingDetails },
    });

    const { user_id, errors } = resp.data;
    if (errors) {
      setFormIsSubmitting(false);
      return errors;
    }

    identify(user_id);
    setUserId(user_id);
    setFormIsSubmitting(false);
  };

  const updatePhoneNumber = async (data: SignUpFormData) => {
    setFormIsSubmitting(true);
    await axios.request({
      method: 'post',
      url: '/api/updateMarketingLeadWithPhoneNumber',
      data: { ...data, ...utmTrackingDetails, userId },
    });
    setFormIsSubmitting(false);
  };

  const initialValues = {
    concerns: [],
    currentAge: null,
    whenRetiring: null,
    maritalStatus: null,
    savings: null,
    firstName: null,
    email: null,
    advisorCallTime: null,
    phone: null,
    verificationCode: '',
  };

  useEffect(() => {
    track(adConversionEvents.funnelFirstStep);
  }, []);

  return (
    <FormikWizard
      formName={FORM_NAME}
      initialValues={initialValues}
      onSubmit={updatePhoneNumber}
      validationSchema={SignUpFormSchema}
    >
      {(helpers, currentPageIndex, setPageIndex) => (
        <Flex column>
          <WizardStep
            pageName={SignUpContainerSteps.Concerns}
            buttonText="Continue"
            hideBackButton
          >
            <ConcernsStep />
          </WizardStep>
          <WizardStep
            pageName={SignUpContainerSteps.CurrentAge}
            buttonText="Continue"
          >
            <AgeStep />
          </WizardStep>
          <WizardStep
            pageName={SignUpContainerSteps.WhenRetiring}
            buttonText="Continue"
          >
            <WhenRetiringStep />
          </WizardStep>
          <WizardStep
            pageName={SignUpContainerSteps.MaritalStatus}
            buttonText="Continue"
          >
            <MaritalStep />
          </WizardStep>
          <WizardStep
            pageName={SignUpContainerSteps.Savings}
            buttonText="Continue"
          >
            <SavingsStep />
          </WizardStep>
          <WizardStep
            pageName={SignUpContainerSteps.CraftingRetirementPlan}
            hideBackButton
          >
            <CraftingRetirementPlanLoadingStep
              onComplete={() => {
                setPageIndex(currentPageIndex + 1);
              }}
            />
          </WizardStep>
          <WizardStep pageName={SignUpContainerSteps.BadFit} hideBackButton>
            <BadFitStep
              onComplete={() => {
                setPageIndex(currentPageIndex + 1);
              }}
              age={helpers.values.currentAge}
              whenRetiring={helpers.values.whenRetiring}
            />
          </WizardStep>
          <WizardStep
            pageName={SignUpContainerSteps.NameAndEmail}
            numberOfStepsToGoBack={3}
          >
            <EmailAndFirstNameStep />
            <FormButton
              marginTop={40}
              type="button"
              disabled={
                helpers.errors.firstName ||
                helpers.errors.email ||
                formIsSubmitting
              }
              onClick={async () => {
                handleTrack(SignUpContainerSteps.NameAndEmail, FORM_NAME);
                const formIsValid = await validateFields<SignUpFormData>(
                  helpers,
                  ['email', 'firstName'],
                );

                if (formIsValid) {
                  const errors = await createMarketingLead(helpers.values);
                  if (errors?.email) {
                    helpers.setFieldError(
                      'email',
                      'This email has already been taken.',
                    );
                  } else {
                    setPageIndex(currentPageIndex + 1);
                  }
                }
              }}
            >
              Next Question
            </FormButton>
          </WizardStep>
          <WizardStep
            pageName={SignUpContainerSteps.AdvisorCallTime}
            buttonText="Next Question"
          >
            <AdvisorCallTimeStep />
          </WizardStep>
          <WizardStep pageName={SignUpContainerSteps.NoCall} hideBackButton>
            <NoCallStep
              onComplete={() => setPageIndex(currentPageIndex + 1)}
              advisorCallTimeValue={helpers.values.advisorCallTime}
            />
          </WizardStep>
          <WizardStep
            pageName={SignUpContainerSteps.Phone}
            numberOfStepsToGoBack={2}
            buttonText="Request Your Consultation"
            submit
          >
            <Flex column>
              <PhoneStep />
            </Flex>
          </WizardStep>
          <WizardStep
            pageName={SignUpContainerSteps.Confirm}
            hideBackButton
            preventSubmission
          >
            <ConfirmedStep />
          </WizardStep>
        </Flex>
      )}
    </FormikWizard>
  );
};
